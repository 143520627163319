<template>
    <div class="main-contents info_mf code">
        <div class="tit">자격증정보 등록/수정<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')"/></div>
        <div class="sub-tit"><p>자격증정보 등록/수정</p></div>
        <div class="enter_inform">
            <div class="form">
                <div class="label">자격증코드</div>
                <div class="list">
                    <InputComp title="자격증코드"  maxByte="6" :rules="{'required':true}" v-model="licenseDetail.licenseCd" :disabled="liceSeq != ''"/>
                </div>
            </div>
            <div class="form">
                <div class="label">자격증명</div>
                <div class="list">
                    <InputComp title="자격증명"  maxByte="100" :rules="{'required':true}" v-model="licenseDetail.licenseNm"/>
                </div>
            </div>
            <div class="form">
                <div class="label">발급기관명</div>
                <div class="list">
                    <InputComp title="발급기관명"  maxByte="100" :rules="{'required':true}" v-model="licenseDetail.issOrgNm"/>
                </div>
            </div>
            <div class="form">
                <div class="label">자격증등급</div>
                <div class="list">
                    <SelectComp cdId="PRO116"  maxByte="6" title="자격증등급" :rules="{'required':true}" v-model="licenseDetail.licenseGradeCd"/>
                </div>
            </div>
            <div class="form">
                <div class="label">사용여부</div>
                <div class="list">
                    <SelectComp list="Y:예,N:아니오"  maxByte="1" title="사용여부" :rules="{'required':true}" v-model="licenseDetail.useYn"/>
                </div>
            </div>
        </div>
        <div class="select_btn">
            <div class="btn_01" @click="movePage('back')">취소</div> &nbsp;
            <div class="btn_02" v-if="liceSeq == ''" @click="gopup()">등록</div>
            <div class="btn_02" style="background-color: #FF681D;" v-else @click="gopup()">수정</div>
        </div>
    </div>
</template>

<script>
import SelectComp from "@/components/base/SelectComp.vue";

export default {
    data() {
        return {
            licenseDetail: {
                licenseCd : '',
                licenseNm : '',
                issOrgNm : '',
                licenseGradeCd : '',
                useYn : '',
                licSeq : '',
            },

            liceSeq : this.$route.params.liceSeq || '',

        };
    },

    beforeMount() {
        if(this.liceSeq != '') this.getLicenseInfo();
        if(this.liceSeq != '') this.licenseDetail.licSeq = this.liceSeq;
    },

    methods: {

        movePage(div) {
            if (div == "back")
                this.$router.go(-1);
            else
                this.$router.push("MAN933M01");
        },

        getLicenseInfo() {
            var param = {};
            param.liceSeq = this.liceSeq;

            this.$.httpPost('/api/main/adm/license/getLicenseDetail', {liceSeq : this.liceSeq})
                .then( res=> {
                    // console.log('getLicenseInfo Result', res);

                    this.licenseDetail = res.data.liceInfo || {};
                }).catch(this.$.httpErrorCommon);
        },

        gopup() {
            this.$.popup('/adm/man/MAN933P01')
                .then(res => {
                    if(res) {
                        this.$.httpPost('/api/main/adm/license/setLicense', this.licenseDetail)
                            .then(() => {
                                this.movePage();
                            }).catch(this.$.httpErrorCommon);
                    }
                })
        },
    },
    components: { SelectComp }


}
</script>